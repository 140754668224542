import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import mundialmente from "../../images/mundialmente.svg"
import LuisVaz from "../../images/LuisVaz.jpg"
import FundoBoca from "../../images/FundoBoca.png"
import Boca from "../../images/Boca.png"
import desconhecido from "../../images/desconhecido.svg"
import yeahImg from "../../images/yeah.svg"

import arrowRight from "../../images/arrow-right.svg"


export default function Contacts() {

	const form = useRef();
	const fundoBoca = useRef();
	const boca = useRef();
	const yeah = useRef();
	const textAreaLines = useRef();
	const btn = useRef();

	const from_name = useRef();
	const from_email = useRef();
	const from_message = useRef();
	
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [IP, setIp] = useState("");
	const [disabled, setDisabled] = useState(false);
	const [submit, setSubmit] = useState("enviar");

	const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


	const handleClick = function () {
		fetch("https://geolocation-db.com/json/")
			.then(response => response.json())
			.then((responseJson => {
				setIp(responseJson.IPv4);
			}))
	}


	const sendEmail = (e) => {
		e.preventDefault();

		if (name == "") {
			from_name.current.classList.add("error");
		} else {
			from_name.current.classList.remove("error");
		}

		if (email == "") {			
			from_email.current.classList.add("error");
		} else if (!pattern.test(from_email.current.value)) {			
			from_email.current.classList.add("error");
		} else {
			from_email.current.classList.remove("error");
		}

		if (message == "") {
			from_message.current.classList.add("error");
			textAreaLines.current.classList.add("error")
		} else {
			from_message.current.classList.remove("error");
			textAreaLines.current.classList.remove("error");
		}

		

		if (name != "" && email != "" && pattern.test(from_email.current.value) && message != "") {
			setSubmit("a enviar...");
			emailjs.sendForm('default_service', 'template_mm7dnbo', form.current, 'user_270V9MIvLdoRQ8zJ9VtKv')
				.then(() => {
					setSubmit("enviado!");
					fundoBoca.current.classList.add("success");
          boca.current.classList.add("success");
          yeah.current.classList.add("success");
          btn.current.classList.add("success");

					setTimeout(function () {
            boca.current.classList.remove("success");
            yeah.current.classList.remove("success");
          }, 2000);

					setDisabled(true);

				}, (error) => {
					setSubmit("ups! não enviou");
					console.log(error.text);
				});
		}
	};



	return (
		<section id="contacts" className="p-relative contacts" data-scroll data-scroll-repeat data-scroll-id="contacts" data-scroll-offset="80%, 20%">
			<div className="max-width pt-100">
				<div className="row align-stretch justify-center">
					<div className="col-xs-12 col-md-7 form-wrapper">
						<p className="leading-text">
						<span className="kathy-room">vamos trabalhar?</span>
							<br /><br />
							fale-me do seu projeto com paixão. o seu entusiasmo é uma fonte de inspiração...
						</p>
						<div className="contactForm">
							<form ref={form} onSubmit={sendEmail} onClick={handleClick}>
								<div className="formField">
									<input type="text" name="from_name" ref={from_name} onChange={e => setName(e.target.value)} value={name} placeholder="o seu nome" />
								</div>
								<div className="formField">
									<input type="text" name="from_email" ref={from_email} onChange={e => setEmail(e.target.value)} value={email} placeholder="o seu email" />
								</div>
								<input type="hidden" name="from_cidade" value={IP} />
								<div className="formField p-relative">
									<textarea className="message" name="message" ref={from_message} onChange={e => setMessage(e.target.value)} placeholder="mensagem" value={message} />
									<div className="textarea-lines" ref={textAreaLines}>
										<div className="textarea-line" />
										<div className="textarea-line" />
										<div className="textarea-line" />
										<div className="textarea-line" />
									</div>
								</div>
								<div className="formBottom pb-50">
									<input ref={btn} disabled={disabled} className="btn" type="submit" id="button" value={submit} />
									<div className="arrow-right">
										<img src={arrowRight} />
									</div>
									<p className="successMessage" style={{ display: 'none' }}>Mensagem enviada!</p>
								</div>
								<div id="emailErr" className="error" style={{ display: 'none' }}>
									<p>O endereço de email é inválido.</p>
								</div>
								<div id="allRequiredErr" className="error" style={{ display: 'none' }}>
									<p>Todos os campos são de<br />preenchimento obrigatório.</p>
								</div>
							</form>
						</div>
					</div>

					<div className="col-xs-10 col-md-5 pt-50 justify-center">
						<div className="luis-vaz-wrapper hidden-xs show-md">
							<img className="mundialmente" src={mundialmente} alt="mundialmente" />
							<img className="luis-vaz-image" src={LuisVaz} alt="luis-vaz-designer" />
							<img ref={fundoBoca} className="black-queixo" src={FundoBoca} />
							<img ref={boca} className="queixo" src={Boca} />
							<img className="desconhecido" src={desconhecido} alt="desconhecido" />
							<img ref={yeah} className="yeah" src={yeahImg} alt="yeah" />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
