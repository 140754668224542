
import React from "react";
import gsap from "gsap";


class Cursor extends React.Component {
	constructor(props) {
		super(props);

		this.mouse = false;
		this.mouseIsHover = false;
		this.mouseIsHoverLeftArrow = false;
		this.mouseIsHoverRightArrow = false;
		this.mouseIsHoverBall = false;

		this.plus = React.createRef();
		this.follower = React.createRef();
		this.left = React.createRef();
		this.right = React.createRef();
		this.ball = React.createRef();

		this.porfolioHome = ".plus-js";
		this.portfolioItems = ".ball-js";
		this.logotiposLeft = ".logotipos .Glide-leftArrow";
		this.logotiposRight = ".logotipos .Glide-rightArrow";
	}


	componentDidMount() {

		function isTouchDevice() {
			const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
			const mq = function (query) {
				return window.matchMedia(query).matches;
			};

			if ("ontouchstart" in window || window.DocumentTouch) {
				return true;
			}

			// include the 'heartz' as a way to have a non matching MQ to help terminate the join
			// https://git.io/vznFH
			const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
				""
			);
			return mq(query);
		}



		if (typeof window !== 'undefined') {

			if (!isTouchDevice()) {
				// set the starting position of the cursor outside of the screen
				let circlePos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
				let mousePos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };


				gsap.to({}, 0.01, {
					repeat: -1,
					onRepeat: () => {


						circlePos.x += (mousePos.x - circlePos.x) / 5;
						circlePos.y += (mousePos.y - circlePos.y) / 5;

						if (this.follower.current) {

							gsap.set(this.follower.current, {
								x: circlePos.x,
								y: circlePos.y
							});


							gsap.to(this.follower.current, 0, {
								scale: this.mouse ? 1 : 0,
								autoAlpha: this.mouse ? 1 : 0,
								borderColor: this.mouseIsHover ? "#ff664b" : "inherit",
								backgroundColor: this.mouseIsHover ? "#ff664b" : "inherit"
							});
						}

						if (this.plus.current) {

							gsap.set(this.plus.current, {
								x: circlePos.x,
								y: circlePos.y
							});

							gsap.to(this.plus.current, 0.4, {
								scale: this.mouseIsHoverPlus ? 1 : 0,
								autoAlpha: this.mouseIsHoverPlus ? 1 : 0
							});

						}

						if (this.ball.current) {

							gsap.set(this.ball.current, {
								x: circlePos.x,
								y: circlePos.y
							});

							gsap.to(this.ball.current, 0.4, {
								scale: this.mouseIsHoverBall ? 1 : 0,
								autoAlpha: this.mouseIsHoverBall ? 1 : 0
							});

						}

						if (this.left.current) {
							gsap.set(this.left.current, {
								x: circlePos.x,
								y: circlePos.y
							});

							gsap.to(this.left.current, 0.1, {
								scale: this.mouseIsHoverLeftArrow ? 1 : 0,
								autoAlpha: this.mouseIsHoverLeftArrow ? 1 : 0
							});

						}

						if (this.right.current) {
							gsap.set(this.right.current, {
								x: circlePos.x,
								y: circlePos.y
							});

							gsap.to(this.right.current, 0.1, {
								scale: this.mouseIsHoverRightArrow ? 1 : 0,
								autoAlpha: this.mouseIsHoverRightArrow ? 1 : 0
							});


						}
					}


				});

				// add listener to track the current mouse position
				document.addEventListener("mousemove", e => {
					mousePos.x = e.clientX;
					mousePos.y = e.clientY;
				});

				// add listener to track the current mouse hover
				document.addEventListener("mousemove", e => {

					this.mouse = true;

					if (e.target.closest(this.porfolioHome)) {
						this.mouseIsHoverPlus = true;
						this.mouse = false;
					} else {
						this.mouseIsHoverPlus = false;
					}


					if (e.target.closest(this.portfolioItems)) {
						this.mouseIsHoverBall = true;
						this.mouse = false;
					} else {
						this.mouseIsHoverBall = false;
					}


					if (e.target.closest(this.logotiposLeft)) {
						this.mouseIsHoverLeftArrow = true;
						this.mouse = false;
					} else {
						this.mouseIsHoverLeftArrow = false;
					}

					if (e.target.closest(this.logotiposRight)) {
						this.mouseIsHoverRightArrow = true;
						this.mouse = false;
					} else {
						this.mouseIsHoverRightArrow = false;
					}



				});
			}

		}

	}

	render() {
		return (
			<div className="cursor">
				<div className="cursor-circle" ref={this.follower} />
				<div className="cursor-plus" ref={this.plus} />
				<div className="cursor-left" ref={this.left} />
				<div className="cursor-right" ref={this.right} />
				<div className="cursor-ball" ref={this.ball} />
			</div>
		);
	}
}

export default Cursor;

