import React, { useState, useEffect, useRef } from 'react';
import useModal from './useModal';
import Menu from "./menu";
import { Link } from "gatsby"
import { isMobile } from "react-device-detect";
import { useLocomotiveScroll } from 'react-locomotive-scroll'


const Header = (props) => {

	const { scroll } = useLocomotiveScroll()
	const { isShowing, toggle } = useModal();
	const [deviceType, setDeviceType] = useState("Desktop");
	const [activeIndex, setActiveIndex] = useState(0);

	const logoWrapper = useRef(null);
	const headerLogo = useRef(null);
	const leftDesigner = useRef(null);


	let plusClass = "orange";
	if (props.type == "single") {
		plusClass = "white";
	}
	if (isShowing) { plusClass = "blue"; }

	let others = props.type;
	let left = props.left || "designer";

	const handleOnClick = index => {
		setActiveIndex(index);
	};

	const handleUserKeyPress = event => {
		const { keyCode } = event;
		if (keyCode == 27) {
			toggle();
		}
	};

	const links = [
		{
			name: "intro",
			to: "#intro",
			className: "menu-item"
		},
		{
			name: "portfólio",
			to: "#portfolio",
			className: "menu-item"
		},
		{
			name: "clientes",
			to: "#clients",
			className: "menu-item"
		},
		{
			name: "contactos",
			to: "#contacts",
			className: "menu-item"
		}
	];


	const navLinks = links.map((link, index) => {
		return (
			<a key={index} onClick={() => handleOnClick(index)} className={activeIndex === index ? "menu-item active" : "menu-item"} href={link.to} data-scroll-to>{link.name}</a>
		);
	});

	// pass prop as class
	// const white = props.white ? "white" : "";
	const white = "";


	useEffect(() => {

		if (scroll && props.type != "post") {
			if (window.innerWidth > 768) {

				scroll.on("scroll", args => {

					if (typeof args.currentElements['intro'] === 'object') {
						let intro = args.currentElements['intro'];
						if (intro.inView) {
							setActiveIndex(0);
						}

					}

					if (typeof args.currentElements['portfolio'] === 'object') {
						let portfolio = args.currentElements['portfolio'];
						if (portfolio.inView) {
							setActiveIndex(1);
						}
					}

					if (typeof args.currentElements['clients'] === 'object') {
						let clients = args.currentElements['clients'];
						if (clients.inView) {
							setActiveIndex(2);
						}
					}


					if (typeof args.currentElements['contacts'] === 'object') {
						let contacts = args.currentElements['contacts'];
						if (contacts.inView) {
							setActiveIndex(3);
						}
					}


					let offset = args.scroll.y / 2.4,
						offsetLeft = args.scroll.y / 8.4,
						dimension = 212,
						leftVal = -29,
						topVal = 100,
						height = dimension - offset,
						left = leftVal - offsetLeft,
						top = topVal - offsetLeft;


					// set logo height and left design opacity

					if (height > 20) {
						headerLogo.current.style.height = height + "px";
						leftDesigner.current.style.opacity = 0.2 + args.scroll.y / 700;
					} else {
						headerLogo.current.style.height = 20 + "px";
						leftDesigner.current.style.opacity = 1;
					}


					// set logoWrapper position
					if (left < -29) {
						logoWrapper.current.style.left = left + "px";
					} else {
						logoWrapper.current.style.left = -29 + "px";
					}

					if (left < -64) {
						logoWrapper.current.style.left = -64 + "px";
					}

					if (top < 100) {
						logoWrapper.current.style.top = top + "px";
					} else {
						logoWrapper.current.style.top = 100 + "px";
					}

					if (top < 50) {
						logoWrapper.current.style.top = 50 + "px";
					}

				});
			}

		}

		if (window.innerWidth <= 1023) {
			setDeviceType("Mobile");
		} else {
			setDeviceType("Desktop");
		}

		const resizeW = function () {


			if (window.innerWidth <= 1023) {
				if (deviceType !== "Mobile") {
					location.reload();
				}
			}

			if (window.innerWidth > 1023) {
				if (deviceType !== "Desktop") {
					location.reload();
				}
			}

		}

		if (isMobile) {
			//logoWrapper.current.removeAttribute("data-scroll-sticky");
		}

		window.addEventListener("resize", resizeW); // Update the width on resize
		window.addEventListener("keydown", handleUserKeyPress);

		return () => {
			window.removeEventListener("keydown", handleUserKeyPress);
			window.removeEventListener("resize", resizeW);
		};

	});


	return (
		<header id="header" className={`header max-width p-relative ${white}`} >
			<div className="row">
				<div ref={logoWrapper} className="col-lg-7 col-xs-12 logo-wrapper" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
					<Link to="/" ref={headerLogo} className="logo"></Link>
				</div>
				{others ?
					<nav className="menu col-xs-offset-7">
						<div className="menu-wrapper" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
							<button className={`open-menu open-menu--${plusClass}`} onClick={toggle}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73.4" xmlSpace="preserve">
									<path d="M30 30.4V0h13v30.4h30V43H43v30.4H30V43H0V30.4h30z" />
								</svg>
							</button>
						</div>
						<Menu isShowing={isShowing} left={left} showreelModal={props.showreelModal} />
					</nav>
					:
					<nav id="menu" className="menu">
						<div className="menu-wrapper hidden-xs show-md" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
							{navLinks}
							<Link to="/showreel/" className="menu-item-orange">showreel</Link>
						</div>
					</nav>}
				<div className="left-logo-wrapper">
					<Link to="/" className="logo--md hidden-md" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction" />
				</div>
				<div id="leftSticky" className="left-designer-wrapper">
					<div data-scroll data-scroll-sticky data-scroll-target="#leftSticky">
						<div className="left-designer" ref={leftDesigner}>
							{left}
						</div>
					</div>
				</div>
				<div id="fixedEmail" className="email-wrapper">
					<div data-scroll data-scroll-sticky data-scroll-target="#fixedEmail" data-scroll-offset="-90%">
						<div className="fixed-email">
							<a href="#email" data-scroll-to>email</a>
						</div>
					</div>
				</div>
			</div>
		</header >
	)
}

export default Header

