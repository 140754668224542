import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/layout';
import Intro from '../components/luisvaz/intro';
import Portfolio from '../components/luisvaz/portfolio';
import Clients from '../components/luisvaz/clients';
import Showreel from '../components/luisvaz/showreel';
import Tagline from '../components/luisvaz/tagline';
import Contacts from '../components/luisvaz/contacts';
import Icons from '../components/icons';
import Cursor from '../components/cursor';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';

const RootIndex = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef();

  const updateStateModal = (state) => {
    setModalVisible(state);
  };

  useEffect(() => {
    setLoaded(true);
  }, [loaded]);

  return (
    <LocomotiveScrollProvider options={{ smooth: true, tablet: { smooth: true }, smartphone: { smooth: false } }} watch={[]} containerRef={containerRef}>
      <main id="top" data-scroll-container ref={containerRef}>
        <div id="loading" className={loaded ? 'loading loaded' : 'loading'}>
          <Layout visible={modalVisible} showreelModal={updateStateModal}>
            <Intro />
            <Portfolio />
            <Clients />
            <Showreel showreelModal={updateStateModal} />
            <Tagline />
            <Contacts />
            <div className="background">
              <div className="gray-bg">
                <div className="orange" data-scroll data-scroll-speed={5} data-scroll-position="top"></div>
              </div>
            </div>
            <Icons />
          </Layout>
        </div>
      </main>
      <Cursor />
    </LocomotiveScrollProvider>
  );
};

export default RootIndex;
