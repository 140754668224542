import React, { useEffect, useRef } from 'react';

import fox from "../../images/fox.svg"
import arrowRight from "../../images/arrow-right.svg"
import years from "../../images/2002-204_.svg"
import luisVaz1 from "../../images/luis-vaz-1.svg"
import luisVaz2 from "../../images/luis-vaz-2.svg"
import luisVaz3 from "../../images/luis-vaz-3.svg"
import luisVaz4 from "../../images/luis-vaz-4.svg"
import { useLocomotiveScroll } from 'react-locomotive-scroll'


const Intro = () => {

	const { scroll } = useLocomotiveScroll()
	const foxCircle = useRef(null);

	useEffect(() => {

		if (scroll) {
			if (window.innerWidth > 768) {

				scroll.on("scroll", args => {

					// set fox rotate
					if (typeof args.currentElements['rotateFoxIcon'] === 'object') {
						foxCircle.current.style.transform = "rotate(" + args.scroll.y + "deg)";
					}

				});
			}
		}

	}, [scroll])

	return (
		<section id="intro" className="intro p-relative max-width" data-scroll-id="intro" data-scroll data-scroll-repeat data-scroll-offset="80%, 20%">

			<div className="row pb-100">
				<div className="col-md-offset-7 col-md-4 col-xs-12 pt-100">
					<div className="row align-center">
						<div className="col-xs-5 fox-mobile-wrapper show-sm hidden-md">
							<div data-scroll data-scroll-speed={1} data-scroll-position="top">
								<img className="fox-mobile mt-50" src={fox} alt="fox" />
							</div>
						</div>
						<div className="col-xs-7 col-md-12">
							<p className="leading-text leading-text--position" data-scroll data-scroll-speed={1} data-scroll-position="top">
								olá<br /><br />
								sou um designer português louco por logotipos, gambozinos e evidências de <br />
								<span className="criatividade"> criatividade</span>
							</p>
						</div>
					</div>
				</div>

			</div>
			<div className="about-wrap p-relative">
				<div className="row pb-100">
					<div className="col-xs-12 col-md-7 pb-100">
						<div className="box pr-30">
							<p className="about-text">
								tenho numerosas referências visuais e culturais retidas na minha memória. consigo visualizar e processar conceitos abstratos, simplificá-los e formar novas ideias, desde catraio. aplico esta capacidade para criar logotipos, design gráfico, branding e nomes originais para marcas em todo o mundo. sigo a minha intuição para trabalhar, cozinhar para quem amo ou colecionar lugares para viver. comecei em paris, passei pelo porto, figueira da foz, castelo de vide, coimbra, lisboa e muitos outros cantinhos portugueses. continuo fascinado pelas identidades que descubro, seja nas pessoas, na música ou na comida, por ver singularidade em todo o lado... também tenho a sorte de ser inspirado pelo amor da
								<a className="cristina" href="https://cristinavaz.com/" target="_blank" rel="noreferrer"> cristina</a>.
							</p>
						</div>
					</div>
					<div className="col-xs-12 col-md-7 hidden-md pb-100">
						<div className="box-designer leading-text">
							<div className="row">
								<div className="col-xs-5 col-xs-offset-1 d-flex">
									<p>designer</p>
									<div className="arrow-right arrow-right--position">
										<img src={arrowRight} />
									</div>
								</div>
								<div className="col-xs-6">
									<p>freelancer<br /> em portugal<br /><br /></p>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-6 d-flex" >
									<img className="data__img" src={years} />
								</div>
								<div className="col-xs-6">
									<p>com os pés<br />
										na terra<br />
										e a cabeça<br />
										na lua.</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-xs-12 col-md-5">

						<div className="row">
							<div className="col-xs-12 d-flex intro-logo-wrapper">
								<div className="col-xs-6 col-sm-3 hidden-md p-0">
									<div className="intro-logo-box-vertical p-relative" data-scroll data-scroll-speed={1} data-scroll-position="top">
										<div className="line-dark" />
										<img src={luisVaz3} />
									</div>
								</div>
								<div className="col-xs-6 col-sm-3 col-md-6 p-0 d-flex center-xs p-0">
									<div className="intro-logo-box" data-scroll data-scroll-speed={1} data-scroll-position="top">
										<img className="intro-logo" src={luisVaz1} />
									</div>
								</div>
								<div className="col-xs-6 col-sm-3 col-md-6 center-xs p-0 d-flex center-xs p-0">
									<div className="intro-logo-box right" data-scroll data-scroll-speed={1} data-scroll-position="top">
										<img className="intro-logo" src={luisVaz2} />
									</div>
								</div>
								<div className="col-xs-6 col-sm-3 hidden-md p-0">
									<div className="intro-logo-box-vertical" data-scroll data-scroll-speed={1} data-scroll-position="top">
										<img className="intro-logo" src={luisVaz4} />
									</div>
								</div>
							</div>
							<div className="col-xs-12 col-md-6 hidden-xs show-md">
								<div className="intro-logo-box-vertical p-relative" data-scroll data-scroll-speed={1} data-scroll-position="top">
									<div className="line-dark" />
									<img src={luisVaz3} />
								</div>
							</div>
						</div>
					</div>

				</div>
				<div className="row p-relative">
					<div className="col-xs-12 col-md-7 hidden-xs show-md">
						<div id="boxDesigner" className="box-designer leading-text p-relative">
							<div className="row">
								<div className="col-xs-5 col-xs-offset-1 d-flex">
									<p>designer</p>
									<div className="arrow-right arrow-right--position">
										<img src={arrowRight} />
									</div>
								</div>
								<div className="col-xs-6">
									<p>freelancer<br /> em portugal<br /><br /></p>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-6 d-flex">
									<img className="data__img" src={years} />
								</div>
								<div className="col-xs-6">
									<p>com os pés<br />
										na terra<br />
										e a cabeça<br />
										na lua.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xs-12 col-md-5 hidden-xs show-md">
						<div className="row intro-logo-wrapper2">
							<div className="col-xs-12 d-flex intro-logo-wrapper">
								<div className="col-xs-3 col-md-6 p-0">
									<div className="intro-logo-box-vertical" data-scroll data-scroll-speed={1} data-scroll-position="center">
										<img className="intro-logo" src={luisVaz4} />
									</div>
								</div>

								<div className="col-xs-10 p-0 center-xs start-sm">

									<div className="intro-logo-box right">
										<div className="fox-icon" data-scroll data-scroll-speed={1} data-scroll-position="top">
											<svg>
												<use xlinkHref="#fox" />
											</svg>
										</div>
										<div data-scroll data-scroll-sticky data-scroll-target="#fixedEmail" data-scroll-offset="-76%" data-scroll-id="rotateFoxIcon">
											<svg ref={foxCircle} className="fox-icon-circle">
												<use xlinkHref="#fox-circle" />
											</svg>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
	)
};
export default Intro