/* eslint-disable react/no-unescaped-entities */
import React from "react";
import arrow from "../../images/arrow-right-clients.svg"


const Clients = (props) => {


	let lineClass = "line center";
	if (props.type == "single") {
		lineClass = "line";
	}

	let clients;
	if (props.type == "single") {
		clients = "pl-50";
	}


	return (
		<section id="clients" className="clients pb-100" data-scroll data-scroll-repeat data-scroll-id="clients" data-scroll-offset="80%, 20%">
			<div className="separator__clients p-relative max-width">
				<div className={`${lineClass}`} />
			</div>
			<div className="max-width">
				<div className={`row ${clients}`} >
					<div className="col-xs-12 p-relative" id="tes">

						<p id="clien" className="clients-title">
							clien—
						</p>

						<div className="clients-title-2" data-scroll data-scroll-sticky data-scroll-target="#tes" data-scroll-offset="-300, 300">
							<p className="rotate">tes</p>
						</div>

						<div id="clientsText" className="clients-text">
							<img className="arrow-right-clients" src={arrow} />
							24 sun — 2ms consulting — 3 house — associação de apoio e segurança psico-social — adega
							d'arte
							— ader-al — adn comatel — advisory &amp; consulting — aefcm — ah! boca santa — ambifood — be
							my
							treasure — bebé vida — beija-me burro — berliners — bimovix — bio wine portal — bifanas
							de
							vendas novas — brasaria — brindar 37 — bula fashion design — by heart academy — bymatilda
							—
							cachorro vadio — camac — cão nosso — carver skateboards — casalinho comum — castello
							prime
							suites — cc talents — celeiro de portugal — centrum — cilix — chamuçaria — chic fourgonnette
							—
							clínica mena gaspar — cloud girl — colorshow — colégio elizângela filomena — comida de rua
							—
							confident — cosmetic zone — curtee — d'alho — d'berlim — dhl — digital marketing — doces
							dos
							miaus — ecoreal — edifício transparente — elemenzo — empregos it — entre os montes —
							enoterra
							—
							escandaleira — escola de hotelaria e turismo de portalegre — etaf construções — expertis
							—
							explica — fábrica dos sabores — factos &amp; fatos — fitwo — flen — following projects —
							formtivity
							— fortitude fpi — fpex group — fred perry — galetto — ginsu — give 4 impact — global wine
							portal
							— golden horse — green fit — grow — heartily — highvision — himmoral — importinox — ice.come
							—
							ice cream roll — in 2 eat — inside bb — joana come a tapa — jobster — johnny johnny — kami
							for
							travel — kidzoland — kinematix tyres — kuiwee — lab tv — labmed — la paleta — laurus —
							licor
							beirão — light blue — lima com pimenta — luis morgado arq — luxtoo — maisis — majora — make
							it —
							malaquias — manteiga boua — mapfre — marca roscofe — marine inland services — marques soares
							—
							medimar — megatours — metro do porto — milano — mimosa — ministerium — mixies frozen yogurt
							—
							molde 50 — mood optic — motoxpert — mudar 24 — multisaber — musique togolaiz — my skull —
							mytos
							— natural stone outlet — next level — nms granites — noddy — nucleus hospitals — nutriuno —
							on
							plastik — oobian — ora bolas! — ori asian food — ópticas lince — owono — padaria nacional
							de
							guimarães — petrus fin holding — photizy — piadina's wine &amp; co — pinch of heaven — pingo
							doce
							—
							pizza hut — portal do jardim — portugal dream coast — portugal limestones — portugall tours
							—
							praia golfe hotel — primor — prevalente — qi news — quase pecado — quinta progresso — raíssa
							—
							rasatitude — rodrigues guimarães — rosana atelier — rubicer — ruca — russel matos — sabichão
							—
							sampedro — segafredo — single track — simple biz — smoking kineto — soma partners — sonae
							—
							specialimo — spider-man — step up — strideways — suite escape — swedi — teenage mutant
							ninja
							turtles — terrius — the darjeeling express — the hot dog lab — the peri-peri club —
							traineira
							—
							tree — trevi — trinkaki hamburgueria premium — turismo 351 — udifar — vanguar tour — vieira
							de
							castro — vila ricca — village natureza — vitaminas — waffelaria — webstylist — we dare to
							event
							— we house — worldog — yammi — yerarky — yummy — zany owl toys — zedelia — zippy
							<span className="bullet">•</span>
						</div>

						<div className="lines">
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
					<div className="max-width p-relative mt-50">
						<p className="clients-subtitle">
							agências <span>&amp;</span><br />
							referências
						</p>
						<p id="agencies" className="clients-text clients-text--medium">
							<img className="arrow-right-clients" src={arrow} />
							67 creative studio — bynet — décimo — designarte — design binário — dwsi — ecrã cúbico —
							extensis — garra publicidade — guzto — hábitos — indústria criativa — konexis — lusiads —
							miscode — oficina do papel — oonify — paper plane — penguin formula — reflexo virtual — rpm
							ideias e comunicação — rex new media — sg marketing — shiftleft — sinapse media — sinemys —
							udesign — ultrawise — vazio studio — yeah works — youon
							<span className="bullet">•</span>
						</p>
						<div className="lines lines--agencias">
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
};
export default Clients