import React, { useEffect } from 'react'
import Header from './header'
import Footer from './footer'
import PropTypes from 'prop-types';
import Seo from './seo'

const Template = (props) => {

	const modal = props.visible;
	const { children } = props;

	let pageClass = "";
	if (props.type == "others") {
		pageClass = "others logotipos";
	} else if (props.type == "design-grafico") {
		pageClass = "others design-grafico";
	} else if (props.type == "branding") {
		pageClass = "others branding";
	} else if (props.type == "naming") {
		pageClass = "others naming";
	} else if (props.type == "post") {
		pageClass = "others post";
	} else if (props.type == "single") {
		pageClass = "others single";
	} else {
		pageClass = "luisvaz";
	}


	return (
		<div id="scroll-direction" className={`container-fluid p-relative ${pageClass}`}>
			<Seo />
			<Header white={modal} type={props.type} left={props.left} showreelModal={props.showreelModal} />
			{children}
			<Footer background={props.background} type={props.type} />
		</div>
	)

}

Template.propTypes = {
	callbacks: PropTypes.string,
	visible: PropTypes.bool,
	children: PropTypes.node.isRequired,
	showreelModal: PropTypes.func,
	detail: PropTypes.string
};

export default Template
