import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from "react-device-detect";

import { gsap, Linear } from 'gsap/all';

import gif1 from "../../images/gif/1_Logotipos.gif"
import gif2 from "../../images/gif/2_DesignerGrafico.gif"
import gif3 from "../../images/gif/3_Branding.gif"
import gif4 from "../../images/gif/4_Naming.gif"
import { useLocomotiveScroll } from 'react-locomotive-scroll';

const Portfolio = () => {

	const { scroll } = useLocomotiveScroll();
	const fadeUp = useRef([]);
	const gif = useRef([]);
	const fadeUpMarque = useRef([]);
	const fadeUpMarque2 = useRef([]);
	const marquee = useRef([]);
	const marquee2 = useRef([]);

	const [tlArray, setTlArray] = useState([]);
	const [tlArray2, setTlArray2] = useState([]);

	const gifArray = [
		{
			"id": "1",
			"name": "design gráfico",
			"src": gif2,
			"href": "https://www.designergrafico.pt/"

		},
		{
			"id": "2",
			"name": "logotipos",
			"src": gif1,
			"href": "https://www.logotipos.pt/"
		},
		{
			"id": "3",
			"name": "branding",
			"src": gif3,
			"href": "https://www.branding.pt/"
		},
		{
			"id": "4",
			"name": "naming",
			"src": gif4,
			"href": "https://www.naming.pt/"
		}
	];



	const handleMouse = (i, isMouseOver) => {
		const transformValue = isMouseOver ? 'translate3d(0, -165px, 0)' : 'translate3d(0, 0, 0)';
		const opacityValue = isMouseOver ? 1 : 0;
		const marqueTransformValue = isMouseOver ? 'translate3d(0, 165px, 0)' : 'translate3d(0, 330px, 0)';

		fadeUp.current[i].style.transform = transformValue;
		gif.current[i].style.opacity = opacityValue;
		fadeUpMarque.current[i].style.transform = marqueTransformValue;
		fadeUpMarque2.current[i].style.transform = marqueTransformValue;

		if (isMouseOver) {
			timeline.play(i);
		} else {
			timeline.pause(i);
		}
	};



	const animateMobile = (i, inView) => {
		if (inView) {
			handleMouse(i, true);
		} else {
			handleMouse(i, false);
		}
	};

	const createTimeline = (el) => {

		const rate = 140;

		const bbox = el.getBBox();
		const distance = bbox.width;
		const time = distance / rate;

		const tl = gsap.timeline({
			paused: true,
			repeat: -1,
		});

		tl.to(el, time, {
			x: `-${distance}`,
			ease: Linear.easeNone,
		});
		return tl;
	};




	const timeline = {
		play: (i) => {
			tlArray[i].restart();
			tlArray2[i].restart();
		},
		pause: (i) => {
			tlArray[i].pause();
			tlArray2[i].pause();
		},
	};

	useEffect(() => {
		if (marquee.current) {
			const updatedTlArray = marquee.current.map((el) => createTimeline(el));
			setTlArray(updatedTlArray);
		}

		if (marquee2.current) {
			const updatedTlArray2 = marquee2.current.map((el) => createTimeline(el));
			setTlArray2(updatedTlArray2);
		}

	}, [marquee.current, marquee2.current]);




	useEffect(() => {
		if (isMobile && scroll) {
			scroll.on('call', func => {
				if (func == "animate") {
					var inView = scroll.scroll.callObj.inView;
					var i;
					if (scroll.scroll.callObj.target) {
						i = scroll.scroll.callObj.target.attributes.index.value;
					} else if (scroll.scroll.callObj.targetEl) {
						i = scroll.scroll.callObj.targetEl.attributes.index.value;
					}
					animateMobile(i, inView);
				}
			});
		}
	}, [isMobile, scroll]);


	return (
		<section id="portfolio" className="portfolio" data-scroll data-scroll-repeat data-scroll-id="portfolio" data-scroll-offset="80%, 20%">
			<div className="separator__portfolio p-relative max-width">
				<div className="line" />
				<p className="section-title">PORTFÓLIO</p>
			</div>

			{gifArray.map((e, i) => (
				<a href={e.href} key={i} index={i} className="marquee" target="_blank" rel="noreferrer" onMouseOver={() => handleMouse(i, true)} onMouseLeave={() => handleMouse(i, false)} data-scroll-call="animate" data-scroll data-scroll-offset="30%, 60%" data-scroll-repeat>

					<svg className="mask" xmlns="http://www.w3.org/3300/svg">
						<defs>
							<mask id="mask-inside-text">
								<rect x="50%" width={580} height={150} y="82.5" fill="#fff">
								</rect>
							</mask>
							<mask id="mask-outside-text">
								<rect width="100%" height={150} y="82.5" fill="#fff" />
							</mask>
						</defs>

						<g mask="url(#mask-outside-text)">
							<g className="marquee-title" ref={el => marquee.current[i] = el}>
								<text className="theCountDown fade-up-js" x="50%" dy={34} textAnchor="middle" transform="translate(0,330)" ref={el => fadeUpMarque.current[i] = el}>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
								</text>
							</g>
						</g>
						<rect className="wrap-gif" width={580} height={330} rx={15} />
						<g className="marquee-title" mask="url(#mask-outside-text)" >
							<text className="theCount fade-up-js" x="50%" y={165} dy={34} textAnchor="middle" ref={el => fadeUp.current[i] = el} >
								<tspan className="outline">{e.name} </tspan>
								<tspan className="outline">{e.name} </tspan>
								<tspan className="outline">{e.name} </tspan>
								<tspan className="outline">{e.name} </tspan>
								<tspan>{e.name} </tspan>
								<tspan className="outline">{e.name} </tspan>
								<tspan className="outline">{e.name} </tspan>
								<tspan className="outline">{e.name} </tspan>
								<tspan className="outline">{e.name} </tspan>
							</text>
						</g>
					</svg>
					<img className="gif plus-js" src={e.src} ref={el => gif.current[i] = el} loading="lazy" />
					<svg className="mask2" xmlns="http://www.w3.org/3300/svg" style={{ pointerEvents: 'none' }}>
						<g className="inside-text" mask="url(#mask-inside-text)">
							<g className="marquee-title-mask" ref={el => marquee2.current[i] = el}>
								<text className="theCountDown theCount-mask fade-up-js" x="50%" dy={34} textAnchor="middle" transform="translate(0,330)" ref={el => fadeUpMarque2.current[i] = el} >
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
									<tspan>{e.name} </tspan>
								</text>
							</g>
						</g>
					</svg>
				</a>
			))}
		</section >
	)
};


export default Portfolio
